import { graphql } from "gatsby";
import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../../components/Layout";
import SidebarAccount from "../../components/SidebarAccount";
import AccountChangeEmail from "../../components/AccountChangeEmail";
import NavLink from "../../components/NavLink";
import { faMap } from "@fortawesome/pro-regular-svg-icons";

const ChangeEmail = (props) => (
  <Layout
    pageName="Change Email"
    secondaryNav={
      <NavLink
        linkPath="/"
        linkText="Map"
        linkIcon={<FontAwesomeIcon icon={faMap} size="lg" />}
      />
    }
  >
    <Container fluid>
      <SidebarAccount {...props} />
      <AccountChangeEmail {...props} />
    </Container>
  </Layout>
);

export default connect((state) => state)(ChangeEmail);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
