import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Button, Col, Row, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog as faUserCogReg } from "@fortawesome/pro-regular-svg-icons";
import {
  accountSendRegisterEmailRequest,
  accountSendRegisterEmailReceive,
} from "../actions";
import NavigateButton from "./NavigateButton";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

/**
 * Form to change the user's email address
 * @param {any} dispatch - dispatches actions to trigger state changes
 * @param {any} account_user_id - current user id
 * @param {any} account_email - current user email address
 * @param {any} [account_change_email_response={}] - response from the server
 * @returns {any}
 */
const AccountChangeEmail = ({
  dispatch,
  account_email,
  account_send_register_email_response,
}) => {
  const [formState, setFormState] = useState({
    old_email: account_email,
    email: "",
    confirm_email: "",
    password: "",
    error: null,
  });
  const { t } = useTranslation();

  const validation = () => {
    const { old_email, email, confirm_email } = formState;

    // validate confirmation email
    if (email !== confirm_email) {
      setFormState({
        ...formState,
        error: {
          message: t(
            "Confirm new email address doesn't match new email address."
          ),
          confirm_email,
        },
      });
      return false;
    }

    // validate new email is not the same as old email
    if (old_email === email) {
      setFormState({
        ...formState,
        error: {
          message: t("New email and current email addresses are the same."),
          email,
        },
      });
      return false;
    }

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validation()) {
      dispatch(
        accountSendRegisterEmailRequest({
          email: formState.email,
        })
      );
    }
  };

  const handleChange = (event, key) =>
    setFormState({
      ...formState,
      [key]: event.target.value,
      error: null,
    });

  const beforeNavigate = () => {
    dispatch(accountSendRegisterEmailReceive({}));
  };

  const { success, data } = account_send_register_email_response || {};

  function renderSuccessAlert() {
    return (
      <>
        <p className="alert alert-success">
          Your request to change the account email address has been submitted.
          Please check the email ({formState.email}) to verify.
        </p>
        <NavigateButton
          beforeNavigate={beforeNavigate}
          path="/account/"
          buttonText={"&lt; " + t("Back to Account")}
        />
      </>
    );
  }

  function renderChangeEmailForm() {
    if (success === true) {
      return renderSuccessAlert();
    }
    return (
      <>
        {success === false && data && data.detail && (
          <Alert variant="danger">{data.detail}</Alert>
        )}
        <Form.Group controlId="formOldEmail">
          <Form.Label>
            <Trans>Current Email Address</Trans>
          </Form.Label>
          <Form.Control type="test" readOnly={true} value={account_email} />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>
            <Trans>New Email Address</Trans>
          </Form.Label>
          <Form.Control
            type="email"
            autoComplete="email"
            isInvalid={
              (!!formState.error &&
                formState.error.email &&
                formState.error.message) ||
              (success === false && data && data.new_email)
            }
            value={formState.email}
            onChange={(e) => handleChange(e, "email")}
            required
          />
          <Form.Control.Feedback type="invalid">
            {data &&
              data.email &&
              data.new_email.length &&
              data.new_email.join(" ")}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formBasicEmail2">
          <Form.Label>
            <Trans>Confirm New Email Address</Trans>
          </Form.Label>
          <Form.Control
            type="email"
            autoComplete="email"
            isInvalid={
              !!formState.error &&
              formState.error.confirm_email &&
              formState.error.message
            }
            value={formState.confirm_email}
            onChange={(e) => handleChange(e, "confirm_email")}
            required
          />
          <Form.Control.Feedback type="invalid">
            {data &&
              data.email &&
              data.confirm_new_email.length &&
              data.confirm_new_email.join(" ")}
          </Form.Control.Feedback>
        </Form.Group>

        {!!formState.error && !!formState.error.message && (
          <Alert variant="danger">{formState.error.message}</Alert>
        )}

        <Form.Group className="mt-4">
          <Button variant="secondary" type="submit">
            <Trans>Submit</Trans>
          </Button>
          <NavigateButton
            wrapperClass="d-inline"
            buttonClass="btn btn-outline-secondary ml-3"
            path="/account/"
            buttonText="Cancel"
            beforeNavigate={beforeNavigate}
          />
        </Form.Group>
      </>
    );
  }

  return (
    <div className="px-md-4 account-container">
      <Row>
        <Col>
          <h2 className="py-4">
            <FontAwesomeIcon icon={faUserCogReg} className="mr-3" fixedWidth />
            <Trans>Account profile</Trans>
          </h2>
          <h6 className="mb-4 h5">
            <Trans>Change Account Email Address</Trans>
          </h6>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>{renderChangeEmailForm()}</Form>
    </div>
  );
};

AccountChangeEmail.propTypes = {
  dispatch: PropTypes.any,
  account_user_id: PropTypes.number,
  account_email: PropTypes.string,
  account_send_register_email_response: PropTypes.object,
};

export default AccountChangeEmail;
